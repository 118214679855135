<template>
  <div>
    <div class="blackO hideDesk">
      <b-row class="splashRow topMiddleBlock">
        <b-img
          v-if="
            tourinfo.course_images === 'Y'
          "
          :class="[splash]"
          :src="
            DEFIMAGESAMS3 +
            'apga' +
            '/' +
            'courses' +
            '/' +
            id +
            '/' +
            tourinfo.course_code +
            '/' +
            tourinfo.course_image_file_1
          "
        >
        </b-img>
        <b-img v-else :src="config.VUE_APP_DEFAULT_COURSE" :class="[splash]">
        </b-img>
      </b-row>
      <div class="gradHome" :class="{'adtGradHome' : develop == 'adt'}"></div>
    </div>
    <div class="tour container">
      <div class="blackO hideMob">
        <b-row class="splashRow topMiddleBlock">
          <b-img
            v-if="
              tourinfo.course_images === 'Y'
            "
            :class="[splash]"
            :src="
              DEFIMAGESAMS3 +
              'apga' +
              '/' +
              'courses' +
              '/' +
              id +
              '/' +
              tourinfo.course_code +
              '/' +
              tourinfo.course_image_file_1
            "
          >
          </b-img>
          <b-img v-else :src="config.VUE_APP_DEFAULT_COURSE" :class="[splash]">
          </b-img>
        </b-row>
        <div class="gradHome" :class="{'adtGradHome' : develop == 'adt'}"></div>
      </div>
      <!-- Title and logo -->

      <template v-if="isMobile(true)">
        <b-row class="TournTitleLogo">
          <b-col cols="12">
              <h2 class="fullTournName" :class="{'adtColour' : develop == 'adt'}">
                {{ tourinfo.full_name }}
              </h2>
              <h4 class="fullCourseName" >
                {{ tourinfo.course_name }}
              </h4>
          </b-col>
          <b-col cols="12">
            <b-img v-if="tourinfo.sponsor_logo != ''" class="MobcourseLogo" :src="tourinfo.sponsor_logo"></b-img>
            <b-img v-else class="MobcourseLogo" :src="tourinfo.tm_params.sponsor_logo"></b-img>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row class="TournTitleLogo">
          <b-col cols="8">
              <h2 class="fullTournName" :class="{'adtColour' : develop == 'adt'}">
                {{ tourinfo.full_name }}
              </h2>
              <h4 class="fullCourseName">
                {{ tourinfo.course_name }}
              </h4>
          </b-col>
          <b-col cols="4">
            <b-img v-if="tourinfo.sponsor_logo != ''" class="courseLogo" :src="tourinfo.sponsor_logo"></b-img>
            <b-img v-else class="courseLogo" :src="tourinfo.tm_params.sponsor_logo"></b-img>
          </b-col>
        </b-row>
      </template>

      <!-- Tourn Info and About Course -->
      <div class="top">
        <b-row class="topMiddleBlock">
          <b-col class="tourInfo">
            <h4
              class="hbh"
              :class="{'adtColour' : develop == 'adt'}"
            >
              Tournament Info
            </h4>
            <b-row>
              <b-col cols="6">
                <p
                  class="Tinfor-title"
                  :class="{'adtSubColour' : develop == 'adt'}"
                >
                  Prize Fund:
                </p>
              </b-col>
              <b-col cols="6">
                <p
                  v-if="tourinfo.total_prize_fund === ''"
                  class="Tinfor-content"
                >
                  N/A
                </p>
                <p
                  v-else
                  class="Tinfor-content"
                >
                  {{ tourinfo.total_prize_fund }}
                </p>
              </b-col>
            </b-row>
            <b-row v-if="tourinfo.winner_found === 'Y'">
              <b-col cols="6">
                <p
                  class="Tinfor-title"
                  :class="{'adtSubColour' : develop == 'adt'}"
                >
                  Winner:
                </p>
              </b-col>
              <b-col cols="6">
                <p
                  class="Tinfor-content"
                >
                  {{ tourinfo.winner_name }}
                </p>
              </b-col>
            </b-row>
            <b-row v-if="tourinfo.previous_winners.previous_winners_entry.cc_name_1.length > 0">
              <b-col cols="6">
                <p
                  class="Tinfor-title"
                  :class="{'adtSubColour' : develop == 'adt'}"
                >
                  Previous Winner:
                </p>
              </b-col>
              <b-col cols="6">
                <p
                  class="Tinfor-content"
                >
                  {{
                    tourinfo.previous_winners.previous_winners_entry.cc_name_1
                  }}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <p
                  class="Tinfor-title"
                  :class="{'adtSubColour' : develop == 'adt'}"
                >
                  Dates:
                </p>
              </b-col>
              <b-col cols="6">
                <p
                  v-if="tourinfo.dates === ''"
                  class="Tinfor-content"
                >
                  N/A
                </p>
                <p
                  v-else
                  class="Tinfor-content"
                >
                  {{ tourinfo.dates }}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <p
                  class="Tinfor-title"
                  :class="{'adtSubColour' : develop == 'adt'}"
                >
                  Field Size:
                </p>
              </b-col>
              <b-col cols="6">
                <p
                  v-if="tourinfo.field_size === ''"
                  class="Tinfor-content"
                >
                  N/A
                </p>
                <p
                  v-else
                  class="Tinfor-content"
                >
                  {{ tourinfo.field_size }}
                </p>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="coursePad">
            <h4
              class="hbh aboutCourse"
              :class="{'adtColour' : develop == 'adt'}"
            >
              About The Course
            </h4>
            <b-row>
              <b-col class="leftPad" cols="6">
                <p
                  class="Tinfor-title"
                  :class="{'adtSubColour' : develop == 'adt'}"
                >
                  Course Name:
                </p>
              </b-col>
              <b-col cols="6">
                <p
                  class="Tinfor-content"
                  :class="{
                  }"
                >
                  {{ tourinfo.course_name }}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="leftPad" cols="6">
                <p
                  class="Tinfor-title"
                  :class="{'adtSubColour' : develop == 'adt'}"
                >
                  City:
                </p>
              </b-col>
              <b-col cols="6">
                <p
                  class="Tinfor-content"
                >
                  {{ tourinfo.course_city }}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="leftPad" cols="6">
                <p
                  class="Tinfor-title"
                  :class="{'adtSubColour' : develop == 'adt'}"
                >
                  Country:
                </p>
              </b-col>
              <b-col cols="6">
                <p
                  class="Tinfor-content"
                >
                  {{ tourinfo.course_country }}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="leftPad" cols="6">
                <p
                  class="Tinfor-title"
                  :class="{'adtSubColour' : develop == 'adt'}"
                >
                  Website:
                </p>
              </b-col>
              <b-col cols="6">
                <p
                  class="Tinfor-content"
                  :class="{'adtLinkColour' : develop == 'adt'}"
                >
                  <template v-if="tourinfo.course_website.includes('https') || tourinfo.course_website.includes('http')">
                    <a
                      v-bind:href="tourinfo.course_website"
                      target="_blank"
                      >{{ tourinfo.course_website }}</a
                    >
                  </template>
                  <template v-else>
                    <a
                      v-bind:href="'https://' + tourinfo.course_website"
                      target="_blank"
                      >{{ tourinfo.course_website }}</a
                    >
                  </template>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="" v-if="tourinfo.ext_scoring_website">
          <b-col>
            <h4
              class="hbh"
              style="margin-bottom:10px"
              :class="{'adtColour' : develop == 'adt'}"
            >
              External Scoring Website
            </h4>
          </b-col>
        </b-row>
        <b-row v-if="tourinfo.ext_scoring_website" class="externalWebscoring">
          <b-col>
            <div class="coursePara">
              <p class="Tinfor-content"
                  :class="{'adtLinkColour' : develop == 'adt'}">
                <a :href="tourinfo.ext_scoring_website" target="_blank">{{tourinfo.ext_scoring_website}}</a>
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row class="" v-if="tourinfo.course_para1">
          <b-col>
            <h4
              class="hbh"
              :class="{'adtColour' : develop == 'adt'}"
            >
              Description
            </h4>
          </b-col>
        </b-row>
        <b-row v-if="tourinfo.course_para1">
          <b-col>
            <div class="coursePara" :class="{addPad : tourinfo.tourn_desc_found == 'N'}">
              <p class="desc">
                {{ tourinfo.course_para1 }}
              </p>
              <p class="desc">
                {{ tourinfo.course_para2 }}
              </p>
              <p
                class="desc"
                v-if="Object.keys(tourinfo.course_para3).length === 0"
              ></p>
              <p class="desc" v-else>
                {{ tourinfo.course_para3 }}
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="tourinfo.tourn_desc_found == 'Y'" class="headTitleRow topMiddleBlock" style="margin-top:3em">
          <b-col class="abovehbh">
            <h2 class="hbh">Overview</h2>
              <p class="Tinfor-content" v-html="tourinfo.tourn_desc">
              </p>
          </b-col>
        </b-row>
      </div>
      <div class="MainInfo" v-if="tourinfo.free_text_details_1.length > 0">
        <div>
          <div v-if="tourinfo.free_text_details_1.length > 0" style="margin-bottom:60px">
            <div class="desc1">
              <h4
                class="hbh"
              >
                {{tourinfo.free_text_desc_1}}
              </h4>
              <p class="Tinfor-content" v-html="tourinfo.free_text_details_1"></p>
            </div>
            <div class="desc2">
              <h4
                class="hbh"
              >
                {{tourinfo.free_text_desc_2}}
              </h4>
              <p class="Tinfor-content" v-html="tourinfo.free_text_details_2"></p>
            </div>
            <div class="desc3">
              <h4
                class="hbh"
              >
                {{tourinfo.free_text_desc_3}}
              </h4>
              <p class="Tinfor-content" v-html="tourinfo.free_text_details_3"></p>
            </div>
            <div class="desc4">
              <h4
                class="hbh"
              >
                {{tourinfo.free_text_desc_4}}
              </h4>
              <p class="Tinfor-content" v-html="tourinfo.free_text_details_4"></p>
            </div>
            <div class="desc5">
              <h4
                class="hbh"
              >
                {{tourinfo.free_text_desc_5}}
              </h4>
              <p class="Tinfor-content" v-html="tourinfo.free_text_details_5"></p>
            </div>
            <div class="desc6">
              <h4
                class="hbh"
              >
                {{tourinfo.free_text_desc_6}}
              </h4>
              <p class="Tinfor-content" v-html="tourinfo.free_text_details_6"></p>
            </div>
            <div class="desc7">
              <h4
                class="hbh"
              >
                {{tourinfo.free_text_desc_7}}
              </h4>
              <p class="Tinfor-content" v-html="tourinfo.free_text_details_7"></p>
            </div>
            <div class="desc8">
              <h4
                class="hbh"
              >
                {{tourinfo.free_text_desc_8}}
              </h4>
              <p class="Tinfor-content" v-html="tourinfo.free_text_details_8"></p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Reports Section -->
    <div class="greyBk" v-if="tourinfo.reports != ''">
      <div class="reportSection container">
        <b-row class="">
          <b-col>
            <h4
              class="hbh"
              :class="{'adtColour' : develop == 'adt'}"
            >
              Reports
            </h4>
          </b-col>
        </b-row>

      <b-row>
          <!-- <span class="reportsPad">
              <b-col  class="dropSel">
                  <b-row class="reportDropdown">
                      <b-col>
                      <div :class="drop">
                          <b-nav card-header pills>
                              <b-dropdown
                                  :text="title"
                                  class="dropdownReport"
                                  v-if="Array.isArray(report.reports_entry)"
                              >
                                  <b-dropdown-item
                                  v-on:click="changeReport"
                                  v-for="(reports, index) in report.reports_entry"
                                  :key="index"
                                  v-show="
                                      reports.report_title !== 'Live Scoring Monitor'
                                  "
                                  :title="reports.report_url"
                                  :value="reports.report_title"
                                  >{{ reports.report_title }}</b-dropdown-item
                                  >
                              </b-dropdown>
                          </b-nav>
                      </div>
                      </b-col>
                  </b-row>
              </b-col>
          </span> -->
      </b-row> 

        <Reports 
          :id="id"
          :Year="season"
          :courseCode="course"
          :reportURL="currentReport"
          :currentReport="reportTitle"
          :config="config"
          :multi_tourns="multi_tourns"
          :pastT="pastT"
          :past_tourns="past_tourns"
          :head="head"
          :tm='tm'
          :tm_params="tm_params"
          :course="course"
          :course_code="course_code"
          :tmparams="tmparams"
          :showmore="showmore"
          :Stableford="Stableford"
          :season="season"
          :code="code"
          :match="match"
          :develop="develop"
        />
        <button :class="{'adtshowMore' : develop == 'adt'}" class="showMore" @click="ShowMore()" v-show="showmore == false">Show More</button>
      </div>
    </div>

    <!-- Scorecard Section -->
    <div class="container">
      <div class="outerbackground">
        <div>
          <b-row class="courseParaSpace">
            <b-col>
              <h4
                class="hbh"
                :class="{'adtColour' : develop == 'adt'}"
              >
                Scorecard
              </h4>
            </b-col>
          </b-row>
          <template v-if="tourinfo.multi_course === 'N'">
            <b-row :class="courseStats">
              <b-col class="table-responsive">
                <table class="courseinfo desktop">
                  <thead>
                    <tr class="title" :class="{'adtColourBK' : develop == 'adt'}">
                      <th>
                        HOLE
                      </th>
                      <th>
                        1
                      </th>
                      <th>
                        2
                      </th>
                      <th>
                        3
                      </th>
                      <th>
                        4
                      </th>
                      <th>
                        5
                      </th>
                      <th>
                        6
                      </th>
                      <th>
                        7
                      </th>
                      <th>
                        8
                      </th>
                      <th>
                        9
                      </th>
                      <th>
                        OUT
                      </th>
                      <th>
                        10
                      </th>
                      <th>
                        11
                      </th>
                      <th>
                        12
                      </th>
                      <th>
                        13
                      </th>
                      <th>
                        14
                      </th>
                      <th>
                        15
                      </th>
                      <th>
                        16
                      </th>
                      <th>
                        17
                      </th>
                      <th>
                        18
                      </th>
                      <th>
                        IN
                      </th>
                      <th>
                        TOTAL
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="scorecard_colors" >
                      <td>
                        PAR
                      </td>
                      <td
                        v-for="(number, index) in tourinfo.course_par
                          .split(',')
                          .slice(0, 9)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ tourinfo.course_out_par.slice(1, 5) }}
                      </td>
                      <td
                        v-for="(number, index) in tourinfo.course_par
                          .split(',')
                          .slice(9, 18)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ tourinfo.course_in_par.slice(1, 5) }}
                      </td>
                      <td>
                        {{ tourinfo.course_total_par }}
                      </td>
                    </tr>
                    <tr class="scorecard_colors">
                      <td>
                        YARDS
                      </td>
                      <td
                        v-for="(number,
                        index) in tourinfo.course_length_yards
                          .split(',')
                          .slice(0, 9)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ outVal(tourinfo.course_length_yards) }}
                      </td>
                      <td
                        v-for="(number,
                        index) in tourinfo.course_length_yards
                          .split(',')
                          .slice(9, 18)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ inVal(tourinfo.course_length_yards) }}
                      </td>
                      <td>
                        {{ totalVal(tourinfo.course_length_yards) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="courseinfo mobile">
                  <thead>
                    <tr class="title" :class="{'adtColourBK' : develop == 'adt'}">
                      <th>
                        H
                      </th>
                      <th>
                        1
                      </th>
                      <th>
                        2
                      </th>
                      <th>
                        3
                      </th>
                      <th>
                        4
                      </th>
                      <th>
                        5
                      </th>
                      <th>
                        6
                      </th>
                      <th>
                        7
                      </th>
                      <th>
                        8
                      </th>
                      <th>
                        9
                      </th>
                      <th>
                        OUT
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="scorecard_colors" >
                      <td>
                        P
                      </td>
                      <td
                        v-for="(number, index) in tourinfo.course_par
                          .split(',')
                          .slice(0, 9)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ tourinfo.course_out_par.slice(1, 5) }}
                      </td>
                    </tr>
                    <tr class="scorecard_colors" >
                      <td>
                        YD
                      </td>
                      <td
                        v-for="(number,
                        index) in tourinfo.course_length_yards
                          .split(',')
                          .slice(0, 9)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ outVal(tourinfo.course_length_yards) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <table class="courseinfo mobile">
                  <thead>
                    <tr class="title" :class="{'adtColourBK' : develop == 'adt'}">
                      <th>
                        10
                      </th>
                      <th>
                        11
                      </th>
                      <th>
                        12
                      </th>
                      <th>
                        13
                      </th>
                      <th>
                        14
                      </th>
                      <th>
                        15
                      </th>
                      <th>
                        16
                      </th>
                      <th>
                        17
                      </th>
                      <th>
                        18
                      </th>
                      <th>
                        IN
                      </th>
                      <th>
                        TOT
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="scorecard_colors">
                      <td
                        v-for="(number, index) in tourinfo.course_par
                          .split(',')
                          .slice(9, 18)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ tourinfo.course_in_par.slice(1, 5) }}
                      </td>
                      <td>
                        {{ tourinfo.course_total_par }}
                      </td>
                    </tr>
                    <tr class="yardsBlue scorecard_colors">
                      <td
                        v-for="(number,
                        index) in tourinfo.course_length_yards
                          .split(',')
                          .slice(9, 18)"
                        :key="index + Math.random()"
                      >
                        {{ number }}
                      </td>
                      <td>
                        {{ inVal(tourinfo.course_length_yards) }}
                      </td>
                      <td>
                        {{ totalVal(tourinfo.course_length_yards) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </template>
          <template v-if="tourinfo.multi_course === 'Y'">
            <div
              v-for="(multi, index) in tourinfo.courses.courses_entry"
              :key="index + Math.random()"
            >
              <h6 class="courseIdMulti" :class="multi.course_X_colour">
                {{ multi.course_X_id }}
              </h6>
              <b-row :class="courseStats">
                <b-col class="table-responsive">
                  <table class="courseinfo desktop">
                    <thead class="multiColour" :class="multi.course_X_colour">
                      <tr class="title" :class="{'adtColourBK' : develop == 'adt'}">
                        <th>
                          H
                        </th>
                        <th>
                          1
                        </th>
                        <th>
                          2
                        </th>
                        <th>
                          3
                        </th>
                        <th>
                          4
                        </th>
                        <th>
                          5
                        </th>
                        <th>
                          6
                        </th>
                        <th>
                          7
                        </th>
                        <th>
                          8
                        </th>
                        <th>
                          9
                        </th>
                        <th>
                          OUT
                        </th>
                        <th>
                          10
                        </th>
                        <th>
                          11
                        </th>
                        <th>
                          12
                        </th>
                        <th>
                          13
                        </th>
                        <th>
                          14
                        </th>
                        <th>
                          15
                        </th>
                        <th>
                          16
                        </th>
                        <th>
                          17
                        </th>
                        <th>
                          18
                        </th>
                        <th>
                          IN
                        </th>
                        <th>
                          TOTAL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="scorecard_colors" >
                        <td>
                          P
                        </td>
                        <td
                          v-for="(number, index) in multi.course_X_par
                            .split(',')
                            .slice(0, 9)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ multi.course_X_out_par.slice(1, 5) }}
                        </td>
                        <td
                          v-for="(number, index) in multi.course_X_par
                            .split(',')
                            .slice(9, 18)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ multi.course_X_in_par.slice(1, 5) }}
                        </td>
                        <td>
                          {{ multi.course_X_total_par }}
                        </td>
                      </tr>
                      <tr class="scorecard_colors" >
                        <td>
                          YD
                        </td>
                        <td
                          v-for="(number,
                          index) in multi.course_X_length_yards
                            .split(',')
                            .slice(0, 9)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ outVal(multi.course_X_length_yards) }}
                        </td>
                        <td
                          v-for="(number,
                          index) in multi.course_X_length_yards
                            .split(',')
                            .slice(9, 18)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ inVal(multi.course_X_length_yards) }}
                        </td>
                        <td>
                          {{ totalVal(multi.course_X_length_yards) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="courseinfo mobile">
                    <thead>
                      <tr class="title" :class="{'adtColourBK' : develop == 'adt'}">
                        <th>
                          H
                        </th>
                        <th>
                          1
                        </th>
                        <th>
                          2
                        </th>
                        <th>
                          3
                        </th>
                        <th>
                          4
                        </th>
                        <th>
                          5
                        </th>
                        <th>
                          6
                        </th>
                        <th>
                          7
                        </th>
                        <th>
                          8
                        </th>
                        <th>
                          9
                        </th>
                        <th>
                          OUT
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="scorecard_colors" >
                        <td>
                          P
                        </td>
                        <td
                          v-for="(number, index) in multi.course_X_par
                            .split(',')
                            .slice(0, 9)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ multi.course_X_out_par.slice(1, 5) }}
                        </td>
                      </tr>
                      <tr class="scorecard_colors" >
                        <td>
                          YD
                        </td>
                        <td
                          v-for="(number,
                          index) in multi.course_X_length_yards
                            .split(',')
                            .slice(0, 9)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ outVal(multi.course_X_length_yards) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <table class="courseinfo mobile">
                    <thead>
                      <tr class="title" :class="{'adtColourBK' : develop == 'adt'}">
                        <th>
                          10
                        </th>
                        <th>
                          11
                        </th>
                        <th>
                          12
                        </th>
                        <th>
                          13
                        </th>
                        <th>
                          14
                        </th>
                        <th>
                          15
                        </th>
                        <th>
                          16
                        </th>
                        <th>
                          17
                        </th>
                        <th>
                          18
                        </th>
                        <th>
                          IN
                        </th>
                        <th>
                          TOT
                        </th>
                      </tr>
                    </thead>
                    <tbody class="">
                      <tr class="">
                        <td
                          v-for="(number, index) in multi.course_X_par
                            .split(',')
                            .slice(9, 18)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ multi.course_X_in_par.slice(1, 5) }}
                        </td>
                        <td>
                          {{ multi.course_X_total_par }}
                        </td>
                      </tr>
                      <tr class="yardsBlue">
                        <td
                          v-for="(number,
                          index) in multi.course_X_length_yards
                            .split(',')
                            .slice(9, 18)"
                          :key="index + Math.random()"
                        >
                          {{ number }}
                        </td>
                        <td>
                          {{ inVal(multi.course_X_length_yards) }}
                        </td>
                        <td>
                          {{ totalVal(multi.course_X_length_yards) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </div>
          </template>
        </div>
      </div>
    </div>
    
    <!-- News Section -->
    <div class="tournamentNews" v-if="showNews">
      <div class="container">
        <b-row class="courseParaSpace">
          <b-col>
            <h4
              class="hbh"
            >
              Tournament News
            </h4>
          </b-col>
        </b-row>
        <Post v-if="wptag.length" 
          :tag="wptag[0].id" 
          :count="wptag[0].count" 
          :course_code="course_code"
          :Year="season"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Reports from "@/components/tournamentReports.vue";
import apiCall from "@/services/api.js";
import Post from "@/components/posts.vue";
export default {
  name: "Tournament",
  props: ["course_code", "id", "develop", "config", 'qsc'],
  components: {
    Reports,
    Post
  },
  data() {
    return {
      tourinfo: [],
      splash: "splash",
      splashText: "splashText",
      logo: "logo",
      overlay: "overlay",
      courseStats: "courseStats",
      tmparams: [],
      report: [],
      showmore:false,
      wptag: "",
      Stableford: "",
      season: "",
      code: "",
      showNews: false,
      match: ""
    };
  },
  methods: {
    gettournPosts: function() {
      console.log("InPost")
      return axios.get(
        process.env.VUE_APP_WP_ASIAN_TOUR_API_URL +
          "wp/v2/tags?slug=" +
          this.tourinfo.code
        )
      .then(response => {
        this.wptag = response.data;
        if (this.wptag[0].count > 0) {
          this.showNews = true
        }
      });
    },
    ShowMore: function(){
      this.showmore = true
    },
    changeReport: function(event) {
      console.log('Change Event')
      var report = event.target.getAttribute("title");
      console.log("report")
      console.log(report)
      var title = event.target.getAttribute("value");
      console.log("title")
      console.log(title)
      this.title = title;
      return (
        (report),
        
        axios
          .get(report)
          .then(response => 
          (this.data = response.data)
          (console.log("this.data"))
          (console.log(this.data))
          )
      );
    },
    isMobile() {
      if( screen.width <= 990 ) {
        return true;
      }
      else {
        return false;
      }
    },
    outVal(value) {
      var split = value.split(",");
      var front9 = split.slice(0, 9);
      for (var i = 0; i < front9.length; i++) front9[i] = +front9[i];
      var sum = front9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    inVal(value) {
      var split = value.split(",");
      var back9 = split.slice(9, 18);
      for (var i = 0; i < back9.length; i++) back9[i] = +back9[i];
      var sum = back9.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    totalVal(value) {
      var split = value.split(",");
      for (var i = 0; i < split.length; i++) split[i] = +split[i];
      var sum = split.reduce(function(a, b) {
        return a + b;
      }, 0);
      return sum;
    },
    // async notLogin() {
    //   try {
    //     // First call, await for call to return
    //     let first_call = await apiCall.tmParams(this.id, this.code);
    //     this.DEFIMAGESAMS3 = first_call.data.tm_params.DEFIMAGESAMS3;
    //     this.tourinfo = first_call.data;
    //     this.until = first_call.data.days_away;
    //     this.showGender = first_call.data.show_gender;
    //     this.is_qualifier = first_call.data.is_qualifier;
    //     this.stableford = first_call.data.stableford;
    //     this.loading = false;
    //     // Second call, await for call to return
    //     let second_call = await apiCall.report(this.reportTitle);
    //     this.data = second_call.data;
    //     console.log("data");
    //     console.log(this.data);
    //     console.log("showGender");
    //     console.log(this.showGender);
    //     this.loading = false;
    //   } catch (error) {
    //     console.log("error in await calls");
    //     console.log(error);
    //     throw error;
    //   }
    // },
  },
  computed: {
    filterReports: function() {
      if (Array.isArray(this.report)) {
        return this.report.filter(
          (reports_entry) =>
            reports_entry.report_title.includes("Tournament Entries") ||
            reports_entry.report_title.includes("Score") ||
            // reports_entry.report_title.includes("Live") ||
            reports_entry.report_title.includes("Final")
        );
      } else return this.report; 
    },
    currentReport: function() {
      if (Array.isArray(this.filterReports))
        return this.filterReports[this.filterReports.length - 1].report_url;
      else return this.filterReports.report_url;
    },
    // filterExemptions: function() {
    //   return this.tourinfo.reports.reports_entry.filter(
    //     reports_entry =>
    //       !reports_entry.report_title.indexOf("Tournament Entries") ||
    //       !reports_entry.report_title.indexOf("Round 1 Draw") ||
    //       !reports_entry.report_title.indexOf("Round 2 Draw") ||
    //       !reports_entry.report_title.indexOf("Round 3 Draw") ||
    //       !reports_entry.report_title.indexOf("Round 4 Draw") ||
    //       !reports_entry.report_title.indexOf("Round 5 Draw") ||
    //       !reports_entry.report_title.indexOf("Round 6 Draw") ||
    //       !reports_entry.report_title.indexOf("Round 1 Scores Only") ||
    //       !reports_entry.report_title.indexOf("Round 2 Scores Only") ||
    //       !reports_entry.report_title.indexOf("Round 3 Scores Only") ||
    //       !reports_entry.report_title.indexOf("Round 4 Scores Only") ||
    //       !reports_entry.report_title.indexOf("Round 5 Scores Only") ||
    //       !reports_entry.report_title.indexOf("Round 6 Scores Only") ||
    //       !reports_entry.report_title.indexOf("Round 2 Scoreboard") ||
    //       !reports_entry.report_title.indexOf("Round 3 Scoreboard") ||
    //       !reports_entry.report_title.indexOf("Round 4 Scoreboard") ||
    //       !reports_entry.report_title.indexOf("Round 5 Scoreboard") ||
    //       !reports_entry.report_title.indexOf("Round 6 Scoreboard") ||
    //       !reports_entry.report_title.indexOf("Final Result") ||
    //       !reports_entry.report_title.indexOf("Live Scoring Monitor")
    //   );
    // },
    // currentReport: function(cr) {
    //   if (this.report !== "") {
    //     if (Array.isArray(this.tourinfo.reports.reports_entry)) {
    //       cr = this.tourinfo.reports.reports_entry.slice(-1);
    //     } else cr = this.tourinfo.reports.reports_entry.report_url;
    //   } else cr = this.report;
    //   return cr;
    // },
    reportTitle: function(url) {
      if (this.course_code.includes('QS') || this.course_code.includes('QF')|| this.course_code.includes('Q2') || this.course_code.includes('Q3') || this.course_code.includes('Q4') || this.course_code.includes('Q5')) {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          this.config.VUE_APP_API +
          'apga' +
          '/cache/' +
           'qsc' +
          '/' +
          this.id +
          "/" +
           this.id +
          "-" +
          this.course_code +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscores") !== -1) {
          var subRep5 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext5 = this.currentReport.indexOf("~", subRep5);
          var repParams1 = this.currentReport.substr(
            subRep5,
            indNext5 - subRep5
          );

          if (repParams1 && repParams1.indexOf(".cgi") < 0) {
            url =
              this.config.VUE_APP_API +
              'apga' +
              '/cache/' +
               'qsc' +
              '/' +
              this.tourinfo.tm_params.season_code +
              "/" +
              this.tourinfo.tm_params.season_code +
              "-" +
              this.course_code +
              "-scores-" +
              repParams1 +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/" + "dev" + "/cache/" + "dev" + "/" + "215S" + "/" + "215S" + "-" + "AUS7" + "-scores-" + $scope.repParams + ".json"+"?randomadd=1603894106415";
          } else {
            url =
              this.config.VUE_APP_API +
              'apga' +
              '/cache/' +
               'qsc' +
              '/' +
              this.tourinfo.tm_params.season_code +
              "/" +
              this.tourinfo.tm_params.season_code +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1 ) {
          {
            url =
              this.config.VUE_APP_API +
              'apga' +
              '/cache/' +
               'qsc' +
              '/' +
              this.tourinfo.tm_params.season_code +
              "/" +
              this.tourinfo.tm_params.season_code +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep6 = this.currentReport.lastIndexOf("round=") + 6;
          var indNext6 = this.currentReport.indexOf("~", subRep6);
          var drawRnd5 = this.currentReport.substr(subRep6, indNext6 - subRep6);
          url =
            this.config.VUE_APP_API +
            'apga' +
            '/cache/' +
             'qsc' +
            '/' +
            this.tourinfo.tm_params.season_code +
            "/" +
            this.tourinfo.tm_params.season_code +
            "-" +
            this.course_code +
            "-draw-R" +
            drawRnd5 +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep4 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext4 = this.currentReport.indexOf("~", subRep4);
          var resNum4 = this.currentReport.substr(subRep4, indNext4 - subRep4);
          console.log("resNum === ");
          console.log(resNum4);
          url =
            this.config.VUE_APP_API +
            'apga' +
            '/cache/' +
             'qsc' +
            '/' +
            this.tourinfo.tm_params.season_code +
            "/" +
            this.tourinfo.tm_params.season_code +
            "-" +
            this.course_code +
            "-result-result-" +
            resNum4 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            this.config.VUE_APP_API +
            'apga' +
            '/cache/' +
            'qsc' +
            '/' +
            this.tourinfo.tm_params.season_code +
            "/" +
            this.tourinfo.tm_params.season_code +
            "-" +
            this.course_code +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url;
      } else {
      if (this.currentReport.indexOf("tmentry") !== -1) {
        url =
          this.config.VUE_APP_API +
          'apga' +
          '/cache/' +
          this.develop +
          '/' +
          this.id +
          "/" +
           this.id +
          "-" +
          this.course_code +
          "-entry-entries.json?randomadd=" +
          new Date().getTime();
      } else {
        if (this.currentReport.indexOf("tmscores") !== -1) {
          var subRep2 = this.currentReport.lastIndexOf("params=") + 7;
          var indNext2 = this.currentReport.indexOf("~", subRep2);
          var repParams = this.currentReport.substr(
            subRep2,
            indNext2 - subRep2
          );

          if (repParams && repParams.indexOf(".cgi") < 0) {
            url =
              this.config.VUE_APP_API +
              'apga' +
              '/cache/' +
              this.develop +
              '/' +
              this.tourinfo.tm_params.season_code +
              "/" +
              this.tourinfo.tm_params.season_code +
              "-" +
              this.course_code +
              "-scores-" +
              repParams +
              ".json" +
              "?randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/" + "dev" + "/cache/" + "dev" + "/" + "215S" + "/" + "215S" + "-" + "AUS7" + "-scores-" + $scope.repParams + ".json"+"?randomadd=1603894106415";
          } else {
            url =
              this.config.VUE_APP_API +
              'apga' +
              '/cache/' +
              this.develop +
              '/' +
              this.tourinfo.tm_params.season_code +
              "/" +
              this.tourinfo.tm_params.season_code +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmlsmon") !== -1 ) {
          {
            url =
              this.config.VUE_APP_API +
              'apga' +
              '/cache/' +
              this.develop +
              '/' +
              this.tourinfo.tm_params.season_code +
              "/" +
              this.tourinfo.tm_params.season_code +
              "-" +
              this.course +
              "-scores-latest.json" +
              "?gt=y&randomadd=" +
              new Date().getTime();
            // var url = "http://dev-auspga.ocs-software.com/api/dev/cache/dev/215S/215S-AUS7-scores-latest.json?gt=y&randomadd=1573131881374"
          }
        } else if (this.currentReport.indexOf("tmdraw") !== -1) {
          var subRep = this.currentReport.lastIndexOf("round=") + 6;
          var indNext = this.currentReport.indexOf("~", subRep);
          var drawRnd = this.currentReport.substr(subRep, indNext - subRep);
          url =
            this.config.VUE_APP_API +
            'apga' +
            '/cache/' +
            this.develop +
            '/' +
            this.tourinfo.tm_params.season_code +
            "/" +
            this.tourinfo.tm_params.season_code +
            "-" +
            this.course_code +
            "-draw-R" +
            drawRnd +
            "-draw.json" +
            "?randomadd=" +
            new Date().getTime();
        } else if (this.currentReport.indexOf("tmresult") !== -1) {
          var subRep1 = this.currentReport.lastIndexOf("result=") + 7;
          var indNext1 = this.currentReport.indexOf("~", subRep1);
          var resNum1 = this.currentReport.substr(subRep1, indNext1 - subRep1);
          console.log("resNum === ");
          console.log(resNum1);
          url =
            this.config.VUE_APP_API +
            'apga' +
            '/cache/' +
            this.develop +
            '/' +
            this.tourinfo.tm_params.season_code +
            "/" +
            this.tourinfo.tm_params.season_code +
            "-" +
            this.course_code +
            "-result-result-" +
            resNum1 +
            ".json" +
            "?randomadd=" +
            new Date().getTime();
        } else {
          url =
            this.config.VUE_APP_API +
            'apga' +
            '/cache/' +
            this.develop +
            '/' +
            this.tourinfo.tm_params.season_code +
            "/" +
            this.tourinfo.tm_params.season_code +
            "-" +
            this.course_code +
            "-result-result-PF.json" +
            "?randomadd=" +
            new Date().getTime();
        }
      }
      return url; 
      }
    },
    filterEntry: function(enter) {
      if (Array.isArray(this.tourinfo.reports.reports_entry)) {
        enter = this.tourinfo.reports.reports_entry.filter(
          (tourinfo) => !tourinfo.report_title.indexOf("Tour")
        );
      } else {
        enter = [this.tourinfo.reports.reports_entry];
      }
      return enter;
    },
    filterOne: function(one) {
      if (Array.isArray(this.tourinfo.reports.reports_entry)) {
        one = this.tourinfo.reports.reports_entry.filter(
          (tourinfo) => !tourinfo.report_title.indexOf("Round 1")
        );
        if (one.length === 0) {
          one = null;
        } else one;
      } else {
        one = null;
      }
      return one;
    },
    filterTwo: function(one) {
      if (Array.isArray(this.tourinfo.reports.reports_entry)) {
        one = this.tourinfo.reports.reports_entry.filter(
          (tourinfo) => !tourinfo.report_title.indexOf("Round 2")
        );
        if (one.length === 0) {
          one = null;
        } else one;
      } else {
        one = null;
      }
      return one;
    },
    filterThree: function(one) {
      if (Array.isArray(this.tourinfo.reports.reports_entry)) {
        one = this.tourinfo.reports.reports_entry.filter(
          (tourinfo) => !tourinfo.report_title.indexOf("Round 3")
        );
        if (one.length === 0) {
          one = null;
        } else one;
      } else {
        one = null;
      }
      return one;
    },
    filterFour: function(one) {
      if (Array.isArray(this.tourinfo.reports.reports_entry)) {
        one = this.tourinfo.reports.reports_entry.filter(
          (tourinfo) => !tourinfo.report_title.indexOf("Round 4")
        );
        if (one.length === 0) {
          one = null;
        } else one;
      } else {
        one = null;
      }
      return one;
    },
    filterFive: function(one) {
      if (Array.isArray(this.tourinfo.reports.reports_entry)) {
        one = this.tourinfo.reports.reports_entry.filter(
          (tourinfo) => !tourinfo.report_title.indexOf("Round 5")
        );
        if (one.length === 0) {
          one = null;
        } else one;
      } else {
        one = null;
      }
      return one;
    },
    filterSix: function(one) {
      if (Array.isArray(this.tourinfo.reports.reports_entry)) {
        one = this.tourinfo.reports.reports_entry.filter(
          (tourinfo) => !tourinfo.report_title.indexOf("Round 6")
        );
        if (one.length === 0) {
          one = null;
        } else one;
      } else {
        one = null;
      }
      return one;
    },
    filterSeven: function(one) {
      if (Array.isArray(this.tourinfo.reports.reports_entry)) {
        one = this.tourinfo.reports.reports_entry.filter(
          (tourinfo) => !tourinfo.report_title.indexOf("Round 7")
        );
        if (one.length === 0) {
          one = null;
        } else one;
      } else {
        one = null;
      }
      return one;
    },
    filterFinal: function(one) {
      if (Array.isArray(this.tourinfo.reports.reports_entry)) {
        one = this.tourinfo.reports.reports_entry.filter(
          (tourinfo) => !tourinfo.report_title.indexOf("Final")
        );
        if (one.length === 0) {
          one = null;
        } else one;
      } else {
        one = null;
      }
      return one;
    },
    mapRef: function(ref) {
      if (this.tourinfo.course_gps_lat === "") ref = this.tourinfo.course_name;
      else
        ref =
          this.tourinfo.course_gps_lat + "," + this.tourinfo.course_gps_long;
      return ref;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.course_code.includes('QS') || this.course_code.includes('QF')|| this.course_code.includes('Q2') || this.course_code.includes('Q3') || this.course_code.includes('Q4') || this.course_code.includes('Q5')) {
      axios //add API Call
        .get(
          process.env.VUE_APP_TIC_BASE +
            'qsc' +
            "/" +
            this.id +
            "/" +
            this.id +
            "-" +
            this.course_code +
            "-tmticx.json?randomadd=" +
            new Date().getTime()
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
          this.tourinfo = response.data,
          this.tmparams = response.data,
          this.DEFIMAGESAMS3 = response.data.tm_params.DEFIMAGESAMS3,
          this.report = response.data.reports.reports_entry,
          this.Stableford = response.data.stableford,
          this.code = response.data.code,
          this.gettournPosts()
        )); 
      } else {
      axios //add API Call
        .get(
          process.env.VUE_APP_TIC_BASE +
            this.develop +
            "/" +
            this.id +
            "/" +
            this.id +
            "-" +
            this.course_code +
            "-tmticx.json?randomadd=" +
            new Date().getTime()
        ) // Page number changed on each page based on JSON page id you are calling
        .then((response) => (
          this.tourinfo = response.data,
          this.match = response.data.match_play,
          this.tmparams = response.data,
          this.DEFIMAGESAMS3 = response.data.tm_params.DEFIMAGESAMS3,
          this.report = response.data.reports.reports_entry,
          this.Stableford = response.data.stableford,
          this.code = response.data.code,
          this.gettournPosts()
        )); 
      }
    }, 1000);
  },
  created() {
    apiCall 
      .tmParamsBase()
      .then(({ data }) => {
        this.DEFIMAGESAMS3 = data.tm_params.DEFIMAGESAMS3;
        console.log( "this.DEFIMAGESAMS3 428" )
        console.log( this.DEFIMAGESAMS3 )
        this.tm_params = data;
        this.multi_tourns = data.multi_tourns;
        if (this.multi_tourns == 'Y') {
         this.pastT = data.past_tourns.past_tourns_entry.code; 
        }
        if (Array.isArray(data.past_tourns.past_tourns_entry)) {
          console.log("isArray");
          this.past_tourns = data.past_tourns.past_tourns_entry;
        } else {
          console.log("is not Array");
          this.past_tourns = [data.past_tourns.past_tourns_entry];
        }
        this.tm = data;
        this.head = data.full_name;
        this.course = data.code;
        this.okfound = data.ok_found;
        this.reports = data.reports.reports_entry;
        this.season = data.tm_params.season_code;
        this.course = data.code;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  // created() {
  //   setTimeout(() => {
  //     axios //add API Call
  //       .get(
  //         process.env.VUE_APP_TIC_BASE +
  //           'qsc' +
  //           "/" +
  //           this.id +
  //           "/" +
  //           this.id +
  //           "-" +
  //           this.course_code +
  //           "-tmticx.json?randomadd=" +
  //           new Date().getTime()
  //       ) // Page number changed on each page based on JSON page id you are calling
  //       .then((response) => (
  //         this.tourinfo = response.data,
  //         this.qsc = 'qsc'
  //       ));
  //   }, 1000); 
  // }
};
</script>

<style scoped>
.externalWebscoring {
  margin-bottom: 20px;
}
.adtColourBK > th{
  background-color: #76c3bd;
}
.adtLinkColour > a {
  color: #76c3bd!important;
  text-decoration: none!important;
}
.adtLinkColour > a:hover {
  color: #1c857c!important;
}
::v-deep .adtSubColour {
  color: #707070!important;
}
::v-deep .adtColour {
  color: #76c3bd!important;
}
::v-deep .adtGradHome {
  background-color: #76c3bd!important;
  background-image: linear-gradient(to right, #76c3bd , #1c857c)!important;
}
::v-deep table.scores-only-table > tbody > tr > td > span.homeposnum{
  color: #5897E6 !important;
  font-weight: 500;
  position: relative; 
  top: -4px!important;
}

.adtshowMore {
  background-color: #76c3bd!important;
}
.adtshowMore:hover {
  background-color: #1c857c!important;
}
.showMore {
  border-radius: 0px;
  width: 324px;
  height: 59px;
  font-weight: 500;
  color: #fff !important;
  line-height: 3.3;
  text-transform: uppercase;
  border: 0px;
  background-color: #042F62;
  margin: auto;
  display: block;
  /* margin-top: 30px; */
  font-size: 14px;
}
.showMore:hover {
  border-radius: 0px;
  width: 324px;
  height: 59px;
  font-weight: 500;
  color: #fff !important;
  line-height: 3.3;
  text-transform: uppercase;
  border: 0px;
  background-color: #41a2f6;
  margin: auto;
  display: block;
  /* margin-top: 30px; */
  font-size: 14px;
}
.desc1 {
  padding-bottom: 40px;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #ffffff;
  border-color: #ffffff;
}
::v-deep .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  float: right;
  margin-top: 10px;
}
::v-deep .btn-secondary {
  color: #7E7E7E;
  background-color: #ffffff;
  border-color: #ebebeb;
  border-radius: 0px;
  font-size: 16px;
  width: 365px;
  height: 43px;
  border: 0px solid;
  text-align: left;
  margin-bottom: 30px;
}
::v-deep h6.courseIdMulti.B {
  float: none;
  font-size: 20px!important;
  font-weight: 500;
}
::v-deep h6.courseIdMulti.Y {
  float: none;
  font-size: 20px!important;
  font-weight: 500;
}
::v-deep h6.courseIdMulti.C {
  float: none;
  font-size: 20px!important;
  font-weight: 500;
}
::v-deep h6.courseIdMulti.R {
  float: none;
  font-size: 20px!important;
  font-weight: 500;
}
::v-deep h6.courseIdMulti.G {
  float: none;
  font-size: 20px!important;
  font-weight: 500;
}
::v-deep h6.courseIdMulti.O {
  float: none;
  font-size: 20px!important;
  font-weight: 500;
}



::v-deep thead.multiColour.B > tr > th {
  background-color: #333333 !important;
}
::v-deep thead.multiColour.Y > tr > th {
  background-color: #d8db22 !important;
}
::v-deep thead.multiColour.C > tr > th {
  background-color: #1e90ff !important;
}
::v-deep thead.multiColour.R > tr > th {
  background-color: #bb0000 !important;
}
::v-deep thead.multiColour.G > tr > th {
  background-color: #3c9933 !important;
}
::v-deep thead.multiColour.O > tr > th {
  background-color: #e07020 !important;
}


::v-deep thead.multiColour.B  {
  float: none!important;
}
::v-deep thead.multiColour.Y  {
  float: none!important;
}
::v-deep thead.multiColour.C  {
  float: none!important;
}
::v-deep thead.multiColour.R  {
  float: none!important;
}
::v-deep thead.multiColour.G  {
  float: none!important;
}
::v-deep thead.multiColour.O  {
  float: none!important;
}
::v-deep tr > th:first-child {
  z-index: 99;
}
::v-deep #reportTable {
  padding-bottom: 0!important;
  margin-bottom: 0px;
  /* overflow: scroll!important;
  overflow-x: hidden!important;
  height: 650px; */
}
.greyBk {
  padding-bottom: 10px;
  padding-top: 45px;
  background-color: #F5F5F5;
  margin-bottom: 30px;
}
.gradHome {
  background-color: #5897E6;
  background-image: linear-gradient(to right, #5897E6 , #A8B7C9);
  height: 10px;
  width: 100%;
  /* position: absolute;
  top: 190px; */
}
.reportSection {
  margin-top: 30px;
  margin-bottom: 30px;
}
.courseParaSpace {
  padding-top: 20px;
}
.coursePara {
  padding-bottom: 0px;
}
p.desc {
  font-size: 14px;
  font-weight: 400;
  color: #707070;
}
::v-deep .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #fff!important;
  background-color: #5897E6;
  border-color: #dee2e6 #dee2e6 #fff;
  border-radius: 0px;
}
::v-deep .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  background-color: #5897E6;
  color: #fff!important;
  border-radius: 0px;
}
::v-deep .nav-tabs .nav-link {
  background-color: #042F62;
  margin-bottom: -1px;
  color: #fff!important;
  border: 0px solid transparent;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}
.tabsSection {
  margin-top: 20px;
}
.MobcourseLogo {
  text-align: center;
  width: 175px;
  margin: auto;
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
}
.tournamentNews {
  padding-bottom: 50px;
  /* padding-top: 45px; */
  /* background-color: #F5F5F5; */
}
.newsHeightFix {
  height: 200px;
  overflow: hidden;
}
.newsImage {
  width: 100%;
}
.newsTitle {
  color: #A8B7C9;
  font-size: 10px;
  text-transform: uppercase;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
.newsHeading {
  color: #0A3F7F;
  font-size: 19px;
  font-weight: 500;
}
th {
  padding: 5px;
}
td {
  padding: 5px;
}
.TournTitleLogo {
  margin-top: 40px;
}
.aboutCourse {
  padding-left: 65px;
}
.tour {
  margin-top: 50px;
}
a.freeTextLink {
  font-size: 16px;
}
.extraInfo {
  margin-bottom: 50px;
  margin-top: 50px;
}
::v-deep .dropdown-item {
  font-size: 14px;
}
::v-deep .logospadBottom.col-md-2.col-lg-2.col-12 {
  display: contents;
}
img.sponsoredImages-sec {
  width: 100%;
}
h4.hbh.hideTpartners {
  display: none;
}
.extraLogos.Noads {
  padding-bottom: 0px;
  padding-top: 0px;
}
.extraLogos {
  padding-top: 50px;
  padding-bottom: 50px;
}
.extraImages {
  width: 100%;
  padding-bottom: 30px;
  padding-top: 30px;
}
.mobile {
  display: none;
}
.topMiddleBlock {
  margin-left: 0px;
  margin-right: 0px;
}
.outerbackground {
  /* padding-top: 5%; */
  /* background-color: #ebebeb; */
  /* padding-bottom: 5%; */
}
.abovehbh {
  padding: 0px;
}
.fullTournName {
  font-size: 26px;
  color: #042F62;
  font-weight: 500;
  text-align: left;
  margin-bottom: 10px;
}
.fullCourseName {
  font-weight: 400;
  text-align: left;
  font-size: 20px;
  color: #7E7E7E;
}
.splash {
  width: 100%;
  opacity: 0.7;
}
li {
  list-style-type: none;
}
.splashRow {
  overflow: hidden;
  /* height: 500px; */
  height: 350px;
  /* height: 100%; */
}
h4 {
  font-size: 18pt;
}
.underLine {
  text-decoration: underline;
}

.courseinfo {
  width: 100%;
  font-size: 13px;
  text-align: left;
}
.scorecard_colors {
  color: #6C6C6C!important;
  text-align: center;
}

.top {
  padding-top: 5%;
  /* background-color: #ebebeb; */
  /* padding-bottom: 5%; */
}
.tourInfo {
  height: 250px;
  padding-left: 0;
}
.splashText {
  margin-top: -17%;
  margin-left: 13%;
  width: 80%;
  position: absolute;
  color: white;
}
.btn {
  width: 100%;
  margin: 0.2rem 0.5rem 0.5rem 0.5rem !important;
  background-color: #133f7b;
  border-radius: 10px;
  color: #fff !important;
}

.btn:hover {
  background-color: #7aafef;
}
.btn-group {
  width: 100%;
}
/* ::v-deep .show > .btn-secondary.dropdown-toggle {
  background-color: #7aafef;
}
::v-deep .dropdown-toggle {
  background-color: #133f7b;
  border-radius: 10px;
} */
.dropdown {
  margin: 0.2rem 0.5rem 0.5rem 0.5rem !important;
}
/* ::v-deep .dropdown-toggle:hover {
  background-color: #7aafef;
  border-radius: 10px;
} */

.headTitleRow {
  /* padding-bottom: 4%; */
}
.logo {
  width: 20%;
  margin-left: -45%;
  padding-top: 5%;
}

.overlay {
  /* padding-left: 6%; */
  padding-top: 0%;
  margin-top: -30px;
  max-width: 1216px;
  margin: auto;
}

.leftPad {
  padding-left: 15%;
  padding-right: 0px;
}

.coursePad {
  /* padding-top: 5%; */
}

.MainInfo {
  /* padding-left: calc(3vw + 3vh); */
  padding-top: calc(1vw + 2vh);
  /* padding-right: calc(3vw + 3vh); */
  /* padding-bottom: calc(2vw + 2vh); */
}

.courseStats {
  /* padding-left: 4.5%;
  padding-right: 4%; */
  text-align: center;
  padding-bottom: 2%;
}

.title {
  background-color: #A8B7C9;
  color: white;
}
.yardsBlue {
  color: #fff;
  /* background-color: #00aeef; */
}
th:first-child {
  /* border-top-left-radius: 10px; */
}
th:last-child {
  /* border-top-right-radius: 10px; */
}
td:first-child {
  /* border-bottom-left-radius: 10px; */
}
td:last-child {
  /* border-bottom-right-radius: 10px; */
}
tr.developGreen {
  background-color: #76c3bd !important;
}
.developGreen > .btn {
  background-color: #76c3bd !important;
  border-color: #76c3bd !important;
}
.developGreen > .nav-item > a > .btn {
  background-color: #76c3bd !important;
  border-color: #76c3bd !important;
}
::v-deep .developGreen > .dropdown-toggle {
  background-color: #76c3bd !important;
  border-color: #76c3bd !important;
}
.developGreenText {
  color: #76c3bd !important;
}
.developGreenText > a {
  color: #76c3bd !important;
}
.rowPad {
  padding-bottom: 1%;
}
.courseLogo {
  width: 175px;
  float: right;
}
.logoReports {
  text-align: center;
}
.reportButton {
  font-size: calc(0.5vh + 0.5vw);
}

.mainImg {
  max-width: 75%;
}

#gmap_canvas {
  height: 300px;
  width: 100%;
}

.blackO {
  background-color: rgb(0, 0, 0);
}
.Tinfor-title {
  color: #133f7b;
  font-size: 14px;
  font-weight: 500;
}
::v-deep p.Tinfor-content > a {
  color: #133f7b;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}
.Tinfor-content {
  font-size: 14px;
  font-weight: 400;
  color: #707070;
  /* width: 200px; */
  width: auto;
  word-break: break-word;
}
.hbh {
  color: #0A3F7F;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
}
.single > .nav-link {
  padding: 0;
}
@media only screen and (max-width: 1024px) {
  .splashRow {
    overflow: hidden;
    /* height: 500px; */
    height: 100%!important;
  }
  .splash {
    height: 300px;
  }
  .splashText {
    margin-top: -25%;
  }

  .logo {
    margin-left: -30%;
    padding-top: 20%;
  }
  .splashPad {
    padding-top: 8%;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: 1em;
  }
  .desc {
    margin-left: -100%;
  }
}
@media only screen and (min-width: 991px) {
  .hideDesk {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  .tournamentNews {
    display: none;
  }
  .mobile {
    display: table;
  }
  .desktop {
    display: none;
  }
  .aboutCourse {
    padding-left: 0px;
  }
  .tourInfo {
    background-color: white;
    border-radius: 0px;
    padding: 0px;
    height: auto;
  }
  .coursePad.col {
    padding-left: 0;
    margin-top: 30px;
  }
  .headTitleRow {
    /* padding-bottom: 4%; */
    margin-top: 30px;
  }
  .tour {
    margin-top: 0px;
  }
  .hideMob {
    display: none;
  }
  /* .desc2 {
    padding-bottom: 40px;
  }
  .desc3 {
    padding-bottom: 40px;
  }
  .desc4 {
    padding-bottom: 40px;
  }
  .desc5 {
    padding-bottom: 40px;
  }
  .desc6 {
    padding-bottom: 40px;
  }
  .desc7 {
    padding-bottom: 40px;
  }
  .desc8 {
    padding-bottom: 40px;
  } */
}

@media only screen and (max-width: 500px) {
  .coursePara.addPad {
    padding-bottom: 30px;
  } 
  ::v-deep .courseStats {
    text-align: center;
    padding-bottom: 2%;
    padding-right: 0px;
  }
  ::v-deep tr.scorecard_colors > td {
    padding-left: 3px!important;
    padding-right: 3px!important;
    text-align: center;
  }
}
@media only screen and (max-width: 480px) {
  .logoReports {
    text-align: center;
    margin-top: 30px;
  }
  .splashText {
    margin-top: 0;
    text-align: center;
  }
  .overlay {
    flex-basis: auto;
    background-color: #263056;
    color: white;
  }
  .logo {
    width: 50%;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-left: 0;
  }
  h2 {
    font-size: 1.7em;
  }
  h3 {
    font-size: 1.5em;
  }
  h4 {
    font-size: 1.3em;
  }
  .reportButton {
    font-size: 1em;
  }
  .reportRow {
    padding-bottom: 10%;
  }
  .fullTournName {
    font-size: 24px;
  }
}
@media only screen and (max-width: 768px) {
  .topMiddleBlock {
    margin-bottom: 30px;
  }
  .desc {
    margin-left: auto!important
  }
  .splashText {
    margin-top: 0;
    text-align: center;
  }
  p {
    margin-bottom: 0.3rem;
  }
  .col {
    flex-basis: 100%;
    flex-grow: 1;
    max-width: 100%;
  }
  .leftPad {
    padding-left: 15px;
  }
  .btn {
    width: 100%;
    background-color: #133f7b;
    /* border-radius: 0px; */
    border-radius: 10px;
    margin: 0rem !important;
    margin-bottom: 10px!important;
  }
  ::v-deep .dropdown {
    margin: auto!important;
    margin-bottom: 10px!important;
  }
  .dropdown-toggle {
    background-color: #133f7b;
    border-radius: 0px;
    margin: 0rem !important;
  }
  .fullTournName {
    font-size: 20px;
    text-align: left;
    margin-bottom: 10px;
  }
  .overlay {
    padding-left: 0% !important;
    padding-top: 0%;
    margin-top: -11em;
    background-color: transparent;
  }
}
</style>
